.wrapper {
    h1 {
        max-width: 17ch;
    }

    span {
        display: block;
    }
}

.gallery:nth-child(odd) {
    margin: clamp(40px, calc(4.6875rem + ((1vw - 14.4px) * 3.2407)), 75px) 0 clamp(60px, calc(6.25rem + ((1vw - 14.4px) * 3.7037)), 100px);
}

.gallery:nth-child(even) {
    padding: clamp(40px, calc(4.6875rem + ((1vw - 14.4px) * 3.2407)), 75px) 0 clamp(60px, calc(6.25rem + ((1vw - 14.4px) * 3.7037)), 100px);
    background-color: #fbffe7;
}

.reverse {
    position: fixed;
    bottom: 50px;
    right: 30px;
    z-index: 1;
    background-color: #12486b;
    border: 1px solid rgb(183, 183, 183);
    padding: 10px;
    border-radius: 50%;
}

.titleWrap {
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    text-align: center;

    h3,
    h2 {
        font-style: normal;
        font-weight: 700;
        line-height: 115%;
        text-transform: uppercase;
    }

    h3 {
        font-size: clamp(28px, calc(2.5rem + ((1vw - 14.4px) * 1.1111)), 40px);
        margin-bottom: clamp(14px,
                calc(1.875rem + ((1vw - 14.4px) * 1.4815)),
                30px);
    }

    h2 {
        font-size: clamp(32px, calc(3rem + ((1vw - 14.4px) * 1.4815)), 48px);
        margin-bottom: clamp(14px,
                calc(1.5rem + ((1vw - 14.4px) * 0.9259)),
                24px);
    }

    p {
        font-size: clamp(14px, calc(1rem + ((1vw - 14.4px) * 0.1852)), 16px);
    }
}

.titleTop {
    margin-bottom: clamp(24px,
            calc(3.4375rem + ((1vw - 14.4px) * 2.8704)),
            55px);
}

.images {
    :global {
        .images-wrap {
            display: -webkit-box;
            /* Not needed if autoprefixing */
            display: -ms-flexbox;
            /* Not needed if autoprefixing */
            display: flex;
            margin: clamp(-20px,
                    calc(-1.25rem + ((1vw - 14.4px) * -0.5556)),
                    -14px) 0;
            column-gap: clamp(14px,
                    calc(1.875rem + ((1vw - 14.4px) * 1.4815)),
                    30px);
        }

        .images-column {
            background-clip: padding-box;
        }

        div.fullscreen-group::before {
            background: rgba(255, 255, 255, 0.4);
            backdrop-filter: blur(25px);
        }

        div.fullscreen-container {
            position: relative;
        }

        div.fullscreen-image:last-child {
            position: absolute;
            padding: 20px 40px;
            opacity: 0;
        }

        button.fullscreen-exit-btn {
            width: 40px;
            height: 40px;
            padding: 0;
            margin: clamp(28px, calc(3.125rem + ((1vw - 14.4px) * 2.037)), 50px) clamp(20px, calc(1.875rem + ((1vw - 14.4px) * 0.9259)), 30px);
            background: url("./../../images/icons/exit.svg");
            background-repeat: no-repeat;
            background-size: contain;

            svg {
                display: none;
            }
        }

        button.fullscreen-toggle {
            padding: clamp(20px,
                    calc(2.5rem + ((1vw - 14.4px) * 1.8519)),
                    40px);

            svg {
                display: none;
            }
        }

        button.fullscreen-toggle.toggle--left {
            background: url("./../../images/icons/chevron-left.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        button.fullscreen-toggle.toggle--right {
            background: url("./../../images/icons/chevron-right.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}

.imageDescription {
    :global {
        div.fullscreen-image:last-child {
            padding: 20px 40px calc(20px + 3rem) !important;
        }
    }

    @-moz-document url-prefix() {
        :global {
            div.fullscreen-image:last-child {
                padding: 20px 40px calc(20px + 4rem) !important;
            }
        }
    }
}


.imageTop {
    h3 {
        font-family: PT Root UI;
        font-size: clamp(16px, calc(1.25rem + ((1vw - 14.4px) * 0.3704)), 20px);
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-top: clamp(6px,
                calc(0.875rem + ((1vw - 14.4px) * 0.7407)),
                14px);
    }
}

.image {
    width: 100%;
    height: 100%;
    align-self: auto;
    margin: clamp(14px, calc(1.25rem + ((1vw - 14.4px) * 0.5556)), 20px) 0;

    video,
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }

    p {
        overflow-y: auto;
        height: 2rem;
        position: absolute;
        text-align: center;
        bottom: 5%;
        font-size: .5rem;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        padding: 0 15px;
    }

    @-moz-document url-prefix() {
        p {
            scrollbar-color: #12486b rgb(237, 237, 237) !important;
            scrollbar-width: auto;
            height: 3.5rem;
        }
    }

    p::-webkit-scrollbar {
        width: 5px;
    }

    p::-webkit-scrollbar-thumb {
        transition: 0.3s;
        background-color: #12486b;
        border-radius: 0;
    }

    p::-webkit-scrollbar-track {
        background-color: rgb(183, 183, 183);
        border: 1px solid transparent;
        background-clip: content-box;
    }

}

.container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;
}

.switched {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 350px;
    padding: 20px;
}

.switched {
    position: relative;
    height: clamp(680px, calc(53.125rem + ((1vw - 12.8px) * 66.4063)), 850px);
}

.switched::before {
    content: "";
    position: absolute;
    display: block;
    left: 0px;
    bottom: 0;
    background-image: url("/images/decorsBackground/1.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left bottom;
    width: clamp(320px, calc(25rem + ((1vw - 12.8px) * 31.25)), 400px);
    height: clamp(680px, calc(53.125rem + ((1vw - 12.8px) * 66.4063)), 850px);
}

.switched::after {
    content: "";
    position: absolute;
    display: block;
    right: 0px;
    bottom: 0;
    background-image: url("/images/decorsBackground/2.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;
    width: clamp(320px, calc(25rem + ((1vw - 12.8px) * 31.25)), 400px);
    height: clamp(680px, calc(53.125rem + ((1vw - 12.8px) * 66.4063)), 850px);
}

.switchedWrap {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-rows: 1fr;
    grid-template-columns: auto;

    button:last-child {
        background-color: #419197;
    }

    button:first-child {
        margin-top: 0;
    }
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: clamp(30px, calc(4.0625rem + ((1vw - 12.8px) * 3.6458)), 65px);
}

@media (max-width: 1024px) {
    .switched {
        height: 350px;
    }

    .switched::before,
    .switched::after {
        display: none;
    }
}

@media (max-width: 580px) {
    .images {
        :global {

            button.fullscreen-toggle.toggle--left,
            button.fullscreen-toggle.toggle--right {
                background-position: bottom;
                padding: 25px;
            }

            div.fullscreen-image:last-child {
                padding: 20px 10px;
            }
        }
    }

    .imageDescription {
        :global {
            div.fullscreen-image:last-child {
                padding: 20px 10px calc(20px + 3rem) !important;
            }
        }
    }
}