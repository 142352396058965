.header {
    padding: clamp(12px, calc(0.875rem + ((1vw - 14.4px) * 0.1852)), 14px) 0;
    background-color: #12486b;

    a {
        transition: 0.2s;
    }

    a:hover {
        opacity: 1;
    }

    z-index: 5;
}

.header.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    animation: headerScroll 0.4s ease-in-out forwards;
    padding: clamp(0.625rem, calc(1.25rem + ((1vw - 1.2rem) * 0.6472)), 1.25rem) 0 clamp(0.3125rem, calc(1.25rem + ((1vw - 1.2rem) * 0.9709)), 1.25rem);
}

@keyframes headerScroll {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

.header__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 12rem;
    align-items: center;
    gap: clamp(27px, calc(3.5625rem + ((1vw - 12.8px) * 11.7188)), 57px);
}

.logo {
    img {
        width: clamp(56px, calc(3.875rem + ((1vw - 14.4px) * 0.5556)), 62px);
        min-width: 24px;
        height: clamp(50px, calc(3.5rem + ((1vw - 14.4px) * 0.5556)), 56px);
        object-fit: contain;
    }
}

.menu {
    max-width: 60vw;
}

.menu__logo {
    display: none;
}

.menu ul::-webkit-scrollbar {
    height: 0.19rem;
}

.menu ul::-webkit-scrollbar-thumb {
    transition: 0.3s;
    background-color: #252636;
    border-radius: 0;
}

.menu ul::-webkit-scrollbar-track {
    background-color: rgb(183, 183, 183);
}

.menu ul {
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    align-items: center;
    padding: 17px 0;
    gap: 1.5rem;
    white-space: nowrap;
    font-size: clamp(1.125rem,
            calc(1.125rem + ((1vw - 0.9rem) * -0.1852)),
            1.25rem);

    a {
        position: relative;
        color: white;
        opacity: 0.7;
    }

    a:hover {
        opacity: 1;
    }

    a:hover::before {
        content: '';
        position: absolute;
        bottom: -15px;
        right: 0;
        width: 100%;
        height: 15px;
        background-image: url('/images/icons/menuLine.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top right;
    }

    :global {
        a.active {
            opacity: 1;
        }

        a.active::before {
            content: '';
            position: absolute;
            bottom: -15px;
            right: 0;
            width: 100%;
            height: 15px;
            background-image: url('/images/icons/menuLine.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: top right;
        }
    }

}

.menu__burger {
    display: none;
}

.backetButton {
    position: relative;
    background-color: transparent;

    svg {
        width: 34px;
        height: 34px;
    }

    .number {
        position: absolute;
        top: 6px;
        left: 20px;
        width: fit-content;
        height: fit-content;
        background-color: #12486b;
        border: 1px solid white;
        border-radius: 50%;

        span {
            display: block;
            padding: 0px 5px;
            font-family: PT Root UI;
            font-size: 13px;
            color: white;
        }
    }
}

.right {
    display: flex;
    gap: 1.38rem;
    justify-self: end;
}

.desctop {
    display: inline-block;
}

.mobile {
    display: none;
}

@media (max-width: 1200px) {
    .menu__burger {
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        padding: 0;

        svg {
            width: 2.5rem;
            height: auto;
            max-height: 2.5rem;
        }
    }

    .header__wrapper {
        display: flex;
        justify-content: space-between;
    }

    .left {
        display: flex;
        align-items: center;
        gap: 1.25rem;
    }

    .menu__modal {
        width: 0;
        height: 0;
        transform: scale(0) translateY(-100%);
    }

    .menu__modal.active {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        max-width: 100%;
        width: 100%;
        min-height: 100vh;
        height: 100%;
        margin: 0;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #12486b;
        z-index: 70;
        animation: animMenu 0.7s ease-in-out forwards;
    }

    @keyframes animMenu {
        0% {
            transform: scale(0) translateY(-100%);
            height: 0;
        }

        1% {
            transform: scale(1) translateY(-100%);
            height: 100%;
        }

        50% {
            transform: scale(1) translateY(3%);
            height: 100%;
        }

        75% {
            transform: scale(1) translateY(-2%);
            height: 100%;
        }

        100% {
            transform: scale(1) translateY(0);
            height: 100%;
        }
    }

    .menu__modalWrap {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }

    .menu__logo {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 0 0 auto;
        padding: clamp(15px, calc(1.5625rem + ((1vw - 10.24px) * 1.506)), 25px) clamp(20px, calc(2.5rem + ((1vw - 10.24px) * 3.012)), 40px);
        border-bottom: 1px solid #d9d9d9;

        img {
            width: clamp(56px,
                    calc(3.875rem + ((1vw - 14.4px) * 0.5556)),
                    62px);
            height: clamp(50px, calc(3.5rem + ((1vw - 14.4px) * 0.5556)), 56px);
            object-fit: contain;
        }

        .menu__burger {
            width: 40px;
            height: 40px;
        }
    }

    .menu {
        max-width: 100%;
        overflow-y: hidden;
        flex: 1 1 auto;
        padding: clamp(30px, calc(2.8125rem + ((1vw - 10.24px) * 2.259)), 45px) clamp(20px, calc(2.5rem + ((1vw - 10.24px) * 3.012)), 40px);

        ul {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 28px;
        }

        li {
            font-size: 1.25rem;
            line-height: 1.2em;
        }
    }
}

@media (max-width: 768px) {
    .desctop {
        display: none;
    }

    .mobile {
        display: inline-block;
    }
}