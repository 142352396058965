.wrapper {
    h1 {
        max-width: 17ch;
    }

    h2 {
        font-size: clamp(28px, calc(2.25rem + ((1vw - 14.4px) * 1.1905)), 36px);
    }

    :global {
        .blockListImage {
            max-height: 680px;
        }
    }
}

.subSection {
    text-align: center;
    margin: clamp(40px, calc(4.625rem + ((1vw - 12.8px) * 3.6957)), 74px) auto clamp(60px, calc(6.25rem + ((1vw - 12.8px) * 4.3478)), 100px);

    h2 {
        margin-top: clamp(20px,
                calc(1.875rem + ((1vw - 12.8px) * 1.0417)),
                30px);
        margin-bottom: clamp(18px,
                calc(1.875rem + ((1vw - 14.4px) * 1.1111)),
                30px);

        em {
            white-space: nowrap;
            font-style: normal;
        }

        span {
            color: inherit;
            display: block;
        }
    }
}

.images {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: clamp(18px, calc(1.875rem + ((1vw - 12.8px) * 1.25)), 30px);
    margin: clamp(24px, calc(2.5rem + ((1vw - 12.8px) * 1.6667)), 40px) 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 15px;
    }
}

.subWrap {
    max-width: 844px;
    margin: 0 auto;
}

@media (max-width: 1024px) {
    .wrapper {
        :global {
            .blockListImage {
                max-height: none;
            }
        }
    }

    .images {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 769px) {
    .images {
        .img {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 80%;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 15px;
        }
    }
}

@media (max-width: 768px) {
    .images {
        grid-template-columns: 1fr;
        justify-items: center;

        img {
            width: auto;
            height: clamp(240px,
                    calc(17.5rem + ((1vw - 10.24px) * 5.6818)),
                    280px);
        }
    }
}