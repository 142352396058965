.footer {
    background-color: #12486b;
    font-size: 1.13rem;
    line-height: 1.2em;
    color: white;
    padding-top: clamp(40px, calc(4.6875rem + ((1vw - 14.4px) * 3.2407)), 75px);

    p,
    li {
        line-height: 1.2em;
    }

    nav ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.88rem 3.13rem;
    }

    a {
        transition: 0.2s;
        color: white;
    }

    li {
        transition: 0.3s;
    }

    nav li a {
        border-bottom: 1px solid transparent;
        padding-bottom: 2px;
    }

    nav li:hover a {
        border-color: white;
    }
}

.icon {
    display: none;
}

.iconWhite {
    display: inline;
}

.white {
    background-color: white;
    color: black;

    a {
        color: black;
    }

    a:hover {
        color: #bb2f54;
    }

    .subinfoWrap {
        border-top: 0.06rem solid #353535;
    }

    .subinfo {

        p,
        a {
            opacity: 1;
        }
    }

    .icon {
        display: inline;
    }

    .iconWhite {
        display: none;
    }
}

.main {
    display: grid;
    grid-template-columns: auto auto 1fr;
    gap: clamp(25px, calc(4.6875rem + ((1vw - 14.4px) * 4.6296)), 75px);
    justify-content: start;
}

.socials {
    justify-self: end;

    li:first-child {
        margin-top: 0;
    }

    li {
        margin-top: 0.94rem;
    }

    a,
    p {
        display: flex;
        flex-wrap: wrap;
        gap: 0.63rem;
    }

    a:hover {
        color: #ffffff90;

        img,
        svg {
            opacity: 0.7;
        }
    }

    img,
    svg {
        transition: 0.2s;
    }


    img,
    svg {
        width: 1.0625rem;
        height: 1.0625rem;
        object-fit: contain;
    }

    a,
    p {
        display: flex;
        align-items: center;
    }
}

.logo {
    display: flex;
    align-items: center;
}

.subinfoWrap {
    margin: clamp(35px, calc(3.4375rem + ((1vw - 14.4px) * 1.8519)), 55px) auto 0;
    padding: clamp(1.375rem,
            calc(1.375rem + ((1vw - 1.2rem) * -0.3883)),
            1.75rem) 0 clamp(1.625rem, calc(1.625rem + ((1vw - 1.2rem) * -0.3883)), 2rem);
    border-top: 0.06rem solid white;
}

.subinfo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    gap: clamp(0.4375rem, calc(1.5rem + ((1vw - 1.2rem) * 1.1003)), 1.5rem) clamp(0.625rem, calc(1.875rem + ((1vw - 1.2rem) * 1.2945)), 1.875rem);

    p,
    a {
        font-size: clamp(14px, calc(1rem + ((1vw - 14.4px) * 0.1852)), 16px);
        opacity: 0.7;
    }

    p:nth-child(2) {
        justify-self: center;
    }

    p:nth-child(3) {
        justify-self: end;
    }

    // p:nth-child(2),
    // p:nth-child(3) {
    //     border-bottom: 0.06rem solid #d9d9d9;
    // }
    // p:hover {
    //     border-color: #bb2f54;
    // }
}

@media (max-width: 910px) {
    .main {
        grid-template-columns: 1fr;
        text-align: center;
        justify-items: center;
        gap: 2.5rem;
    }

    .footer nav ul {
        grid-template-columns: 1fr;
    }

    .socials {
        justify-self: center;
    }

    .socials {
        margin-top: 0;

        a,
        p {
            justify-content: center;
        }
    }
}

@media (max-width: 768px) {
    .subinfo {
        grid-template-columns: 1fr;
        justify-items: center;

        p:first-child,
        p:last-child {
            justify-self: center;
        }

        p {
            justify-self: center;
            text-align: center;
        }
    }
}