* {
    padding: 0;
    margin: 0;
    border: 0;
}
*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
nav,
footer,
header,
aside {
    display: block;
}
html,
body {
    width: 100%;
    height: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
body {
    font-family: PT Root UI;
    font-weight: 300;
    line-height: 1.15;
    font-size: clamp(14px, calc(1.125rem + ((1vw - 14.4px) * 0.3704)), 18px);
}
input,
button,
textarea {
    font-family: inherit;
}
input::-ms-clear {
    display: none;
}
button {
    cursor: pointer;
}
button::-moz-focus-inner {
    padding: 0;
    border: 0;
}
a {
    color: black;
}
a,
a:visited {
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}
ul li {
    list-style: none;
}
img {
    max-width: 100%;
    vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Amatic SC;
    font-weight: 700;
    font-size: inherit;
    font-weight: inherit;
}