.pagination-container {
    position: relative;
    z-index: 1;
    display: flex;
    ul {
        display: flex;
        list-style-type: none;
    }
    .pagination-item {
        height: 32px;
        text-align: center;
        margin: auto 4px;
        padding: 2px 12px;
        border-radius: 3px;
        min-width: 32px;
        color: black;
        font-family: "PT Root UI";
        font-size: 16px;
        line-height: 150%;
        &.dots:hover {
            background-color: transparent;
            cursor: default;
        }
        &:hover {
            background-color: #12476b40;
            cursor: pointer;
        }

        &.selected {
            color: white;
            background-color: #12486b;
        }

        .arrow:hover {
            background-color: transparent;
            cursor: pointer;
        }
        .arrow {
            &::before {
                position: relative;
                /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
                content: "";
                /* By using an em scale, the arrows will size with the font */
                display: inline-block;
                width: 0.4em;
                height: 0.4em;
                border-right: 0.12em solid rgba(0, 0, 0, 0.87);
                border-top: 0.12em solid rgba(0, 0, 0, 0.87);
            }

            &.left {
                transform: rotate(-135deg) translate(-50%);
            }

            &.right {
                transform: rotate(45deg);
            }
        }
        &.disabled {
            pointer-events: none;

            .arrow::before {
                border-right: 0.12em solid rgba(0, 0, 0, 0.43);
                border-top: 0.12em solid rgba(0, 0, 0, 0.43);
            }
            &:hover {
                background-color: transparent;
                cursor: default;
            }
        }
    }
    button.pagination-item {
        background-color: transparent;
    }
    .pagination-button,
    .pagination-button.pagination-item:hover {
        background-color: transparent;
        border: 1px solid transparent;
    }
    .pagination-button.pagination-item:hover {
        border-color: black;
    }
}