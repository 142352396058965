.wrapper {
    h1 {
        max-width: 25ch;
    }
}
.gallery:nth-child(odd) {
    margin: clamp(40px, calc(4.6875rem + ((1vw - 14.4px) * 3.2407)), 75px) 0
        clamp(60px, calc(6.25rem + ((1vw - 14.4px) * 3.7037)), 100px);
}
.gallery:nth-child(even) {
    padding: clamp(40px, calc(4.6875rem + ((1vw - 14.4px) * 3.2407)), 75px) 0
        clamp(60px, calc(6.25rem + ((1vw - 14.4px) * 3.7037)), 100px);
    background-color: #fbffe7;
}
.titleWrap {
    max-width: 644px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    h3,
    h2 {
        font-style: normal;
        font-weight: 700;
        line-height: 115%;
        text-transform: uppercase;
    }
    h3 {
        font-size: clamp(28px, calc(2.5rem + ((1vw - 14.4px) * 1.1111)), 40px);
        margin-bottom: clamp(
            14px,
            calc(1.875rem + ((1vw - 14.4px) * 1.4815)),
            30px
        );
    }
    h2 {
        font-size: clamp(32px, calc(3rem + ((1vw - 14.4px) * 1.4815)), 48px);
        margin-bottom: clamp(
            14px,
            calc(1.5rem + ((1vw - 14.4px) * 0.9259)),
            24px
        );
    }
    p {
        font-size: clamp(14px, calc(1rem + ((1vw - 14.4px) * 0.1852)), 16px);
    }
}
.titleTop {
    margin-bottom: clamp(
        24px,
        calc(3.4375rem + ((1vw - 14.4px) * 2.8704)),
        55px
    );
}
.images {
    :global {
        .images-wrap {
            display: -webkit-box; /* Not needed if autoprefixing */
            display: -ms-flexbox; /* Not needed if autoprefixing */
            display: flex;
            margin: clamp(
                    -20px,
                    calc(-1.25rem + ((1vw - 14.4px) * -0.5556)),
                    -14px
                )
                0;
            column-gap: clamp(
                14px,
                calc(1.875rem + ((1vw - 14.4px) * 1.4815)),
                30px
            );
        }
        .images-column {
            background-clip: padding-box;
        }
        div.fullscreen-group::before {
            background: rgba(255, 255, 255, 0.4);
            backdrop-filter: blur(25px);
        }
        div.fullscreen-container {
            position: relative;
        }
        div.fullscreen-image:last-child {
            position: absolute;
            padding: 20px 40px;
            opacity: 0;
        }
        button.fullscreen-exit-btn {
            width: 40px;
            height: 40px;
            padding: 0;
            margin: clamp(28px, calc(3.125rem + ((1vw - 14.4px) * 2.037)), 50px)
                clamp(20px, calc(1.875rem + ((1vw - 14.4px) * 0.9259)), 30px);
            background: url("./../../images/icons/exit.svg");
            background-repeat: no-repeat;
            background-size: contain;
            svg {
                display: none;
            }
        }
        button.fullscreen-toggle {
            padding: clamp(
                20px,
                calc(2.5rem + ((1vw - 14.4px) * 1.8519)),
                40px
            );
            svg {
                display: none;
            }
        }
        button.fullscreen-toggle.toggle--left {
            background: url("./../../images/icons/chevron-left.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
        button.fullscreen-toggle.toggle--right {
            background: url("./../../images/icons/chevron-right.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
    .imageTop {
        h3 {
            font-family: PT Root UI;
            font-size: clamp(
                16px,
                calc(1.25rem + ((1vw - 14.4px) * 0.3704)),
                20px
            );
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            margin-top: clamp(
                6px,
                calc(0.875rem + ((1vw - 14.4px) * 0.7407)),
                14px
            );
        }
    }
    .image {
        width: 100%;
        height: 100%;
        align-self: auto;
        margin: clamp(14px, calc(1.25rem + ((1vw - 14.4px) * 0.5556)), 20px) 0;
        video,
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }
}
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: clamp(30px, calc(4.0625rem + ((1vw - 12.8px) * 3.6458)), 65px);
}
.poemBlock {
    margin: clamp(40px, calc(4.6875rem + ((1vw - 10.24px) * 5.2711)), 75px) 0
        clamp(60px, calc(6.25rem + ((1vw - 10.24px) * 6.0241)), 100px);
}
.poemBlock {
    display: flex;
    gap: 0 clamp(64px, calc(10rem + ((1vw - 14.4px) * 23.0769)), 160px);
}
.poemBlockImage {
    width: clamp(540px, calc(45.5rem + ((1vw - 14.4px) * 45.1923)), 728px);
    height: clamp(534px, calc(45rem + ((1vw - 14.4px) * 44.7115)), 720px);
    border-radius: 15px;
    img {
        width: inherit;
        height: inherit;
        object-fit: cover;
        border-radius: 15px;
    }
}
.poemBlockInfoWrap {
    flex: 1 1 auto;
}
.poemBlockParagraph {
    -webkit-line-clamp: 25;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.poemBlockInfo {
    display: flex;
    flex-direction: column;
    text-align: center;
    p {
        font-size: clamp(
            14px,
            calc(1.125rem + (14 - 18) * ((100vw - 1440px) / (360 - 1440))),
            18px
        );
        font-weight: 300;
        line-height: 135%;
    }
    a {
        flex: 0 0 auto;
        font-size: clamp(14px, calc(1.25rem + ((1vw - 14.4px) * 0.5556)), 20px);
        font-weight: 300;
        line-height: normal;
        letter-spacing: 1px;
        text-decoration-line: underline;
        text-transform: uppercase;
        margin-top: 24px;
    }
    width: 303px;
}
@media (max-width: 1024px) {
.poemBlockParagraph {
    -webkit-line-clamp: 20;
}
}
@media (max-width: 768px) {
    .poemBlock {
        display: flex;
        flex-direction: column;
        gap: clamp(24px, calc(2.5rem + ((1vw - 7.68px) * 3.5714)), 40px) 0;
    }
    .poemBlockImage {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .poemBlockInfo {
        margin: 0 auto;
    }
}
@media (max-width: 580px) {
    .images {
        :global {
            button.fullscreen-toggle.toggle--left,
            button.fullscreen-toggle.toggle--right {
                background-position: bottom;
                padding: 25px;
            }
            div.fullscreen-image:last-child {
                padding: 20px 10px;
            }
        }
    }
}
