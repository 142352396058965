.wrap {
    display: flex;
    gap: clamp(30px, calc(4.0625rem + ((1vw - 14.4px) * 3.2407)), 65px);
}

.info {
    display: grid;
    justify-items: center;
    align-content: start;
    width: 100%;

    h2 {
        margin-top: clamp(18px, calc(1.5rem + ((1vw - 14.4px) * 0.5556)), 24px);
        max-width: 26ch;
        text-align: center;
    }
}

.form {
    display: grid;
    width: 100%;
}

.row {
    display: grid;
    gap: clamp(12px, calc(1.25rem + ((1vw - 14.4px) * 1.9231)), 20px);
    margin-top: clamp(24px, calc(2.5rem + ((1vw - 14.4px) * 1.4815)), 40px);
    border-bottom: 1px solid black;

    input,
    textarea {
        padding: clamp(4px, calc(0.5rem + ((1vw - 14.4px) * 0.3704)), 8px);
    }

    input::placeholder,
    textarea::placeholder,
    input,
    textarea {
        font-size: clamp(14px, calc(1rem + ((1vw - 14.4px) * 0.3704)), 16px);
        font-weight: 400;
    }
}

.image {
    max-width: 625px;
    width: 100%;
    max-height: 711px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 15px;
    }
}

@media (max-width: 1024px) {
    .image {
        display: none;
    }
}