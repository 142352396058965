.link {
    display: inline-flex;
    justify-content: center;
    padding: 0.88rem 1.25rem;
    align-items: center;
    gap: 0.75rem;
    border-radius: 0.31rem;
    background: #fff;
    transition: 0.2s;
    margin-top: clamp(24px, calc(2.5rem + ((1vw - 14.4px) * 1.4815)), 40px);

    color: #419197;
    font-size: clamp(14px, calc(1rem + ((1vw - 14.4px) * 0.1852)), 16px);
    font-weight: 400;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    svg {
        stroke: #419197;
        width: 15px;
        height: 8px;
    }
}
.link.disabled {
    color: rgba(0, 0, 0, 0.3);
}
.link.disabled:hover{
    background: #fff;
}
.link:hover {
    background-color: #dadada;
}