.wrapperTwo {
    padding: clamp(40px, calc(4.6875rem + ((1vw - 10.24px) * 5.2711)), 75px) 0
        clamp(60px, calc(6.25rem + ((1vw - 10.24px) * 6.0241)), 100px);
}
.blockTwo {
    p,
    h2 {
        font-family: PT Root UI;
        font-size: clamp(
            14px,
            calc(1.125rem + (14 - 18) * ((100vw - 1440px) / (360 - 1440))),
            18px
        );
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 0.54px;
        text-transform: none;
    }
    h2 {
        font-weight: 700;
        margin-bottom: clamp(18px, calc(1.875rem + (18 - 30) * ((100vw - 1440px) / (360 - 1440))), 30px);
    }
}
.wrapper {
    position: relative;
    padding: clamp(40px, calc(4.6875rem + ((1vw - 10.24px) * 5.2711)), 75px)
        calc(
            clamp(285px, calc(24.0625rem + ((1vw - 14.4px) * 24.0385)), 385px) -
                clamp(20px, calc(5rem + ((1vw - 14.4px) * 5.5556)), 80px)
        )
        clamp(60px, calc(6.25rem + ((1vw - 10.24px) * 6.0241)), 100px)
        calc(
            clamp(300px, calc(25rem + ((1vw - 14.4px) * 24.0385)), 400px) -
                clamp(20px, calc(5rem + ((1vw - 14.4px) * 5.5556)), 80px)
        );
}
.wrapper::before {
    content: "";
    position: absolute;
    display: block;
    left: 0px;
    bottom: 0;
    background-image: url("/images/poem/imgflowers3.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left bottom;
    max-width: clamp(300px, calc(25rem + ((1vw - 14.4px) * 24.0385)), 400px);
    width: 100%;
    height: 100%;
}
.wrapper::after {
    content: "";
    position: absolute;
    display: block;
    right: 0px;
    bottom: 0;
    background-image: url("/images/poem/imgflowers4.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;
    max-width: clamp(
        285px,
        calc(24.0625rem + ((1vw - 14.4px) * 24.0385)),
        385px
    );
    width: 100%;
    height: 100%;
}
.block {
    h2 {
        font-family: Amatic SC;
        text-align: center;
        font-size: clamp(28px, calc(2.25rem + ((1vw - 14.4px) * 0.7407)), 36px);
        font-weight: 700;
        line-height: 115%;
        letter-spacing: 1.08px;
        margin-bottom: clamp(
            20px,
            calc(2.375rem + ((1vw - 14.4px) * 1.6667)),
            38px
        );
    }
    p {
        text-align: center;
        font-family: PT Root UI;
        font-weight: 300;
        line-height: 130%;
        letter-spacing: 0.54px;
    }
}
.paginationWrapper{
    display: flex;
    justify-content: center;
    margin-top: clamp(30px, calc(3.4375rem + ((1vw - 12.8px) * 2.6042)), 55px);
}
@media (max-width: 1024px) {
    .wrapper {
        padding-left: 0;
        padding-right: 0;
    }
    .wrapper::before,
    .wrapper::after {
        display: none;
    }
    .block {
        p {
            font-size: clamp(
                14px,
                calc(2rem + ((1vw - 10.24px) * 2.7108)),
                32px
            );
        }
    }
}
