.list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: clamp(7px, calc(0.875rem + ((1vw - 12.8px) * 0.7292)), 14px);
}
@media (max-width: 1024px) {
    .list {
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 580px) {
    .list {
        grid-template-columns: 1fr;
    }
}