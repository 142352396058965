.wrapper {
    position: relative;
    margin: clamp(40px, calc(4.6875rem + ((1vw - 10.24px) * 5.2711)), 75px) 0
        clamp(60px, calc(6.25rem + ((1vw - 10.24px) * 6.0241)), 100px);
    padding: 0 clamp(280px, calc(19.75rem + ((1vw - 14.4px) * 8.6538)), 316px) 0
        clamp(250px, calc(18.4375rem + ((1vw - 14.4px) * 10.8173)), 295px);
}
.wrapper::before {
    content: "";
    position: absolute;
    display: block;
    left: 0px;
    bottom: clamp(-100px, calc(-6.25rem + ((1vw - 14.4px) * -3.7037)), -60px);
    background-image: url("/images/bannerOne/1.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left bottom;
    width: clamp(250px, calc(18.4375rem + ((1vw - 14.4px) * 10.8173)), 295px);
    height: clamp(480px, calc(34.875rem + ((1vw - 14.4px) * 18.75)), 558px);
}
.wrapper::after {
    content: "";
    position: absolute;
    display: block;
    right: 0px;
    bottom: clamp(-100px, calc(-6.25rem + ((1vw - 14.4px) * -3.7037)), -60px);
    background-image: url("/images/bannerOne/2.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;
    width: clamp(280px, calc(19.75rem + ((1vw - 14.4px) * 8.6538)), 316px);
    height: clamp(580px, calc(41.8125rem + ((1vw - 14.4px) * 21.3942)), 669px);
}
.wrap {
    display: grid;
    justify-items: center;
    text-align: center;
    h2 {
        color: black;
        max-width: 70ch;
        width: 100%;
        padding: 0 10px;
        margin-top: clamp(
            20px,
            calc(1.75rem + ((1vw - 14.4px) * 0.7407)),
            28px
        );
        span {
            color: inherit;
            margin: 0 0.2em;
        }
    }
    p {
        font-size: clamp(14px, calc(1.25rem + ((1vw - 14.4px) * 1.4423)), 20px);
        font-weight: 300;
        line-height: 150%;
        margin-top: clamp(18px, calc(1.5rem + ((1vw - 14.4px) * 0.5556)), 24px);
        max-width: 45ch;
        width: 100%;
    }
}
@media (max-width: 1024px) {
    .wrapper {
        padding: 0;
    }
    .wrapper::before,
    .wrapper::after {
        display: none;
    }
}