.name {
    position: relative;
    stroke: #419197;
    width: fit-content;
    height: fit-content;
    padding: 0.38rem 0.88rem 0.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 115%;

    span {
        text-wrap: nowrap;
    }
}

.border {
    position: absolute;
    top: -170%;
    left: -5%;
    width: 110%;
}

@media (max-width: 1024px) {
    .border {
        top: -180%;
    }
}
@media (max-width: 768px) {
    .border {
        top: -190%;
    }
}
@media (max-width: 480px) {
    .border {
        top: -200%;
    }
}
@media (max-width: 420px) {
    .border {
        top: -205%;
    }
}