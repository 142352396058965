.wrapper {
    background-color: #f8fddd;
    padding: clamp(40px, calc(4.6875rem + ((1vw - 10.24px) * 5.2711)), 75px) 0
        clamp(60px, calc(6.25rem + ((1vw - 10.24px) * 6.0241)), 100px);
    margin-bottom: clamp(
        40px,
        calc(4.6875rem + ((1vw - 10.24px) * 5.2711)),
        75px
    );
}
.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
        font-weight: 400;
        line-height: 115%;
    }
}
.slider {
    height: clamp(200px, calc(26.25rem + ((1vw - 14.4px) * 52.8846)), 420px);
    margin-top: clamp(18px, calc(1.75rem + ((1vw - 14.4px) * 2.4038)), 28px);
    :global {
        .swiper-slide {
            width: fit-content !important;
        }
    }
    video,
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 15px;
    }
}
.buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: clamp(14px, calc(1.5rem + ((1vw - 14.4px) * 2.4038)), 24px);
}
.sliderNext,
.sliderPrev {
    width: 3.44rem;
    height: 1.75rem;
    border-radius: 0.19rem;
    background: #419197;
    svg {
        width: 16px;
        height: 8px;
    }
}
.sliderPrev {
    svg {
        transform: rotate(180deg);
    }
}
