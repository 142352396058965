.article {
    h1 {
        max-width: 23ch;
    }
}
.info {
    margin: clamp(40px, calc(4.6875rem + ((1vw - 14.4px) * 3.2407)), 75px) 0
        clamp(60px, calc(6.25rem + ((1vw - 14.4px) * 3.7037)), 100px);
    h2 {
        font-family: Amatic SC;
        font-size: clamp(28px, calc(3rem + ((1vw - 14.4px) * 1.8519)), 48px);
        font-weight: 700;
        line-height: 115%;
        text-transform: uppercase;
        margin-bottom: clamp(
            18px,
            calc(2.625rem + ((1vw - 14.4px) * 2.2222)),
            42px
        );
    }
}
.paragraph {
    margin-bottom: clamp(14px, calc(1.5rem + ((1vw - 14.4px) * 0.9259)), 24px);
    h3 {
        font-family: PT Root UI;
        font-size: clamp(
            14px,
            calc(1.625rem + ((1vw - 14.4px) * 1.7857)),
            26px
        );
        font-weight: 700;
        line-height: 150%;
        letter-spacing: 0.03rem;
        margin-bottom: clamp(
            14px,
            calc(1.25rem + ((1vw - 14.4px) * 0.5556)),
            20px
        );
        text-transform: uppercase;
    }
    p {
        font-size: clamp(
            14px,
            calc(1.625rem + ((1vw - 14.4px) * 1.7857)),
            26px
        );
        line-height: 150%;
        letter-spacing: 0.05rem;
    }
}
.otherArticles {
    padding: clamp(40px, calc(4.6875rem + ((1vw - 14.4px) * 3.2407)), 75px) 0
        clamp(60px, calc(6.25rem + ((1vw - 14.4px) * 3.7037)), 100px);
    background-color: #fbffe7;
}
.otherArticlesTitle {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: clamp(24px, calc(3.75rem + ((1vw - 14.4px) * 3.3333)), 60px);
}
.otherArticlesBlockWrap {
    h2 {
        font-size: clamp(32px, calc(3rem + ((1vw - 14.4px) * 1.4815)), 48px);
        margin-bottom: clamp(
            12px,
            calc(1rem + ((1vw - 14.4px) * 0.3704)),
            16px
        );
    }
    a {
        width: 100%;
        font-size: clamp(12px, calc(1rem + ((1vw - 14.4px) * 0.5952)), 16px);
        svg {
            stroke: white;
        }
    }
}
.otherArticlesImage {
    position: relative;
    border-radius: 15px;
    padding-bottom: 90%;
    overflow: hidden;
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        object-fit: cover;
    }
}
@media (max-width: 480) {
    .otherArticlesBlockWrap {
        a {
            font-size: 14px;
        }
    }
}
