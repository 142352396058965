.wrap {
    margin: clamp(40px, calc(4.6875rem + ((1vw - 14.4px) * 3.2407)), 75px) 0 clamp(60px, calc(6.25rem + ((1vw - 14.4px) * 3.7037)), 100px);
}

.product {
    margin: clamp(35px, calc(3.4375rem + ((1vw - 12.8px) * 2.0833)), 55px) 0 clamp(60px, calc(6.25rem + ((1vw - 14.4px) * 3.7037)), 100px);
}

.product {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: clamp(14px, calc(1.875rem + ((1vw - 12.8px) * 3.125)), 30px);
}

.images {
    img {
        width: 100%;
        max-height: clamp(370px,
                calc(39.0625rem + ((1vw - 12.8px) * 49.8047)),
                625px);
        object-fit: cover;
    }
}

.breadcrumbs {
    display: flex;
}

.breadcrumbs li::after {
    content: ">";
    margin: 0 4px;
}

.breadcrumbs li:last-child:after {
    display: none;
}

.info {
    h2 {
        font-family: PT Root UI;
        font-size: clamp(1.25rem, calc(2rem + ((1vw - 0.8rem) * 2.3438)), 2rem);
        font-weight: 400;
        line-height: 115%;
        letter-spacing: 0.96px;
    }

    .price {
        font-family: Amatic SC;
        font-size: clamp(3rem, calc(5rem + ((1vw - 0.8rem) * 6.25)), 5rem);
        font-weight: 700;
        line-height: 150%;

        svg {
            width: clamp(1.875rem,
                    calc(2.5rem + ((1vw - 0.8rem) * 1.9531)),
                    2.5rem);
            height: clamp(2.375rem,
                    calc(4rem + ((1vw - 0.8rem) * 5.0781)),
                    4rem);
        }
    }
}

.productList {
    font-family: PT Root UI;
    line-height: 150%;
    font-weight: 300;

    h3 {
        font-family: inherit;
        line-height: inherit;
        font-weight: 700;
    }

    span {
        font-weight: 400;
    }
}

.link {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.88rem 1.25rem;
    align-items: center;
    gap: 0.75rem;
    border-radius: 0.31rem;
    background: #12486b;
    transition: 0.2s;
    margin-top: clamp(24px, calc(2.5rem + ((1vw - 14.4px) * 1.4815)), 40px);

    color: white;
    font-size: clamp(14px, calc(1rem + ((1vw - 14.4px) * 0.1852)), 16px);
    font-weight: 400;
    letter-spacing: 0.05rem;
    text-transform: uppercase;

    svg {
        width: 15px;
    }
}

.link.disabled {
    color: rgba(255, 255, 255, 0.3);
}

.link.disabled:hover {
    background: #12486b;
}

.link:hover {
    background-color: #093653;
}

.list {
    padding: clamp(40px, calc(4.6875rem + ((1vw - 14.4px) * 3.2407)), 75px) 0 clamp(60px, calc(6.25rem + ((1vw - 14.4px) * 3.7037)), 100px);
    background-color: #fbffe7;

    :global {
        .product__item:hover {
            background-color: white !important;
        }
    }

    .link {
        width: auto;
        margin-left: auto;
        margin-right: auto;
    }
}

.title {
    display: grid;
    justify-items: center;
    text-align: center;

    h2 {
        margin: clamp(14px, calc(2.1875rem + ((1vw - 12.8px) * 2.1875)), 35px) 0 clamp(20px, calc(3.4375rem + ((1vw - 12.8px) * 3.6458)), 55px);
    }
}

.description {
    font-family: PT Root UI;
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
    margin-top: clamp(20px, calc(1.75rem + ((1vw - 12.8px) * 1.5625)), 28px);

    summary {
        position: relative;
        display: flex;
        gap: 0.625rem;
        align-items: center;
        cursor: pointer;
        margin-bottom: clamp(14px,
                calc(1.25rem + ((1vw - 12.8px) * 1.1719)),
                20px);
    }

    ::marker {
        font-size: 0;
        color: transparent;
        opacity: 0;
    }

    svg {
        width: 1rem;
        height: 1rem;
        transform: rotate(180deg);
    }

    ol {
        margin: 5px 0 5px 1.5em;
    }
}

.description[open] {
    svg {
        transform: rotate(0);
    }
}

.popup {
    position: fixed;
    top: clamp(90px, calc(7.5rem + ((1vw - 12.8px) * 3.125)), 120px);
    right: clamp(20px, calc(5rem + ((1vw - 12.8px) * 6.25)), 80px);
    z-index: 998;
    display: flex;
    gap: clamp(10px, calc(1rem + ((1vw - 12.8px) * 0.625)), 16px);
    border-radius: clamp(18px, calc(1.875rem + ((1vw - 12.8px) * 1.25)), 30px);
    padding: clamp(10px, calc(1rem + ((1vw - 12.8px) * 0.625)), 16px);
    background: #f3f3f3;
    animation: animPopup 0.7s ease-in-out forwards;

    .imagesWrap {
        width: clamp(70px, calc(8.5625rem + ((1vw - 12.8px) * 6.9792)), 137px);
    }

    .images {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        border-radius: clamp(10px,
                calc(0.9375rem + ((1vw - 12.8px) * 0.5208)),
                15px);
        overflow: hidden;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .popapInfo {
        display: flex;
        flex-direction: column;
    }

    span {
        font-family: PT Root UI;
        font-size: clamp(0.625rem,
                calc(1.25rem + ((1vw - 0.8rem) * 1.0417)),
                1.25rem);
        font-style: normal;
        font-weight: 300;
        line-height: 115%;
        letter-spacing: 0.6px;
        flex: 0 1 auto;
    }

    h3 {
        font-size: clamp(0.8125rem,
                calc(1.625rem + ((1vw - 0.8rem) * 1.3542)),
                1.625rem);
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        flex: 1 1 auto;
    }

    strong {
        font-family: Amatic SC;
        font-size: clamp(1.5rem, calc(3rem + ((1vw - 0.8rem) * 2.5)), 3rem);
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        flex: 0 1 auto;
    }
}

@keyframes animPopup {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@media (max-width: 768px) {
    .product {
        grid-template-columns: 1fr;
        gap: 30px;
    }

    .images {
        img {
            max-height: none;
        }
    }
}