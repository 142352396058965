.link {
    display: inline-flex;
    justify-content: center;
    padding: 0.88rem 1.25rem;
    align-items: center;
    gap: 0.75rem;
    border-radius: 0.31rem;
    background: #12486B;
    transition: .2s;
    margin-top: clamp(24px, calc(2.5rem + ((1vw - 14.4px) * 1.4815)), 40px);

    color: white;
    font-size: clamp(14px, calc(1rem + ((1vw - 14.4px) * 0.1852)), 16px);
    font-weight: 400;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
}
.link:disabled{
    color: rgba(255, 255, 255, 0.30);
    cursor: no-drop;
}
.link:disabled:hover{
    background: #12486B;
}
.link:hover {
    background-color: #093653;
}