.wrapper {
    background-color: #419197;
    color: white;
    margin: clamp(60px, calc(6.25rem + ((1vw - 10.24px) * 6.0241)), 100px) 0
        clamp(40px, calc(4.6875rem + ((1vw - 10.24px) * 5.2711)), 75px);
    padding: clamp(28px, calc(4.625rem + ((1vw - 14.4px) * 4.2593)), 74px) 0
        clamp(34px, calc(6rem + ((1vw - 14.4px) * 5.7407)), 96px);
}
.wrap {
    display: flex;
    gap: clamp(20px, calc(4.0625rem + ((1vw - 14.4px) * 6.6964)), 65px);
}
.info {
    flex: 1 1 655px;
    h2 {
        font-size: clamp(28px, calc(2.25rem + ((1vw - 14.4px) * 0.7407)), 36px);
        margin: clamp(18px, calc(1.75rem + ((1vw - 14.4px) * 0.9259)), 28px) 0
            clamp(14px, calc(1.5rem + ((1vw - 14.4px) * 0.9259)), 24px);
    }
}
.description {
    p:first-child {
        margin-top: 0;
    }
    p.indent {
        margin-top: clamp(
            18px,
            calc(1.875rem + ((1vw - 14.4px) * 1.1111)),
            30px
        );
    }
}
.image {
    flex: 1 1 560px;
    max-height: 625px;
    img {
        width: 100%;
        height: 100%;
        max-height: inherit;
        object-fit: cover;
        border-radius: 15px;
        object-position: top;
    }
}
@media (max-width: 1024px) {
    .wrap {
        flex-direction: column;
        gap: clamp(20px, calc(1.875rem + ((1vw - 10.24px) * 1.506)), 30px);
    }
    .info {
        flex: 1 1 auto;
    }
    .image {
        flex: 1 1 auto;
        max-height: none;
    }
}