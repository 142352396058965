.wrapper {
    margin: clamp(40px, calc(4.6875rem + ((1vw - 10.24px) * 5.2711)), 75px) 0 clamp(60px, calc(6.25rem + ((1vw - 10.24px) * 6.0241)), 100px);
}

.wrapper.two {
    .image {
        flex: 1 1 560px;
        max-height: none;
    }

    .info {
        flex: 1 1 655px;
    }

    .wrap {
        flex-direction: row-reverse;
    }
}

.wrap {
    display: flex;
    gap: clamp(20px, calc(4.0625rem + ((1vw - 14.4px) * 6.6964)), 65px);
}

.info {
    flex: 1 1 540px;

    h2 {
        margin-bottom: clamp(18px,
                calc(1.5rem + ((1vw - 14.4px) * 0.5556)),
                24px);
    }

    a {
        border-bottom: 1px solid black;
        padding-bottom: 1px;
        line-height: 1.6;
    }
}

.list {

    li.indent:first-child,
    li:first-child {
        margin-top: 0;
    }

    li.indent {
        margin-top: clamp(18px,
                calc(1.875rem + ((1vw - 14.4px) * 1.1111)),
                30px);
    }
}

.description {

    p.indent:first-child,
    p:first-child {
        margin-top: 0;
    }

    p.indent {
        margin-top: clamp(18px,
                calc(1.875rem + ((1vw - 14.4px) * 1.1111)),
                30px);
    }
}

.image {
    flex: 1 1 682px;
    max-height: none !important;

    img {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        max-height: inherit;
        object-fit: cover;
        object-position: top;
    }
}

.images {
    display: flex;
    flex-wrap: wrap;
    margin: clamp(16px, calc(1.5rem + ((1vw - 12.8px) * 0.8333)), 24px) 0;
    gap: 10px;
}

.imagesWrap {
    position: relative;
    width: calc(33% - 5px);
    height: clamp(122px, calc(13.5rem + ((1vw - 12.8px) * 9.7917)), 216px);

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 15px;
    }
}

:global {
    .block__list-images1 {
        width: calc(100% - 0px);
        height: auto;

        img {
            position: relative;
            object-fit: contain;
            max-height: 300px;
        }
    }

    .block__list-images2 {
        width: calc(50% - 5px);
    }
}

.addImage {
    .blockListImages {
        display: flex;
        flex-direction: column;
        gap: 20px;

        img {
            object-position: center;
            border-radius: 10px;
        }

        img:first-child {
            height: 30vw;
            max-height: 420px;
        }
        
        img:last-child {
            height: 40vw;
            max-height: 500px;
        }
    }
}

@media (max-width: 1024px) {
    .wrapper.two {
        .wrap {
            flex-direction: column-reverse;
            gap: clamp(20px, calc(1.875rem + ((1vw - 10.24px) * 1.506)), 30px);
        }

        .info {
            flex: 1 1 auto;
        }

        .image {
            flex: 1 1 auto;
            max-height: none;
        }
    }

    .wrap {
        flex-direction: column;
        gap: clamp(20px, calc(1.875rem + ((1vw - 10.24px) * 1.506)), 30px);
    }

    .info {
        flex: 1 1 auto;
    }

    .image {
        flex: 1 1 auto;
        max-height: none;
    }

    .addImage {
        .blockListImages {
            img:first-child {
                height: 500px;
            }

            img:last-child {
                height: 650px;
            }
        }
    }
}

@media (max-width: 480px) {
    .addImage {
        .blockListImages {
            img:first-child {
                height: 400px;
            }

            img:last-child {
                height: 500px;
            }
        }
    }
}